import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import sm from './slicemachine.config.json';

const accessToken = process.env.PRISMIC_ACCESS_TOKEN
const apiEndpoint = process.env.PRISMIC_ENDPOINT || sm.apiEndpoint;
export const repositoryName = prismic.getRepositoryName(apiEndpoint);

// Update the routes array to match your project's route structure
/** @type {prismic.ClientConfig['routes']} **/
const routes = [
  {
    type: 'homepage',
    path: '/',
  },
  {
    type: 'landing',
    path: '/landing/:uid',
  },
  {
    type: 'article',
    path: '/article/:uid',
  },
  {
    type: 'event',
    path: '/event/:uid',
  },
  {
    type: 'page',
    path: '/:uid',
  },
  {
    type: 'species',
    path: '/species/:uid',
  },
  {
    type: 'document',
    path: '/document/:uid',
  },
  {
    type: 'product',
    path: '/product/:uid',
  },
];

export type MenuType = { cta: { cta?: any; cta_link?: any }; items: any[] };

export function createClient({
  previewData,
  req,
  ...config
}: prismicNext.CreateClientConfig = {}) {
  const client = prismic.createClient(repositoryName, {
    routes,
    accessToken,
    ...config,
  });

  prismicNext.enableAutoPreviews({ client, previewData, req });

  return client;
}

export function linkResolver(doc: any) {
  try {
    const route = routes.find((route) => route.type === doc.type);
    let path = route?.path.replace(':uid', doc.uid);
    if (doc.lang !== 'en-gb') {
      path = `/${doc.lang}${path}`;
    }
    return path || '#';
  } catch {
    return '#';
  }
}

export async function buildMegaMenu(menu: any, previewData: any) {
  const megaMenu: MenuType = {
    cta: { cta: null, cta_link: null },
    items: [],
  };
  const children: Array<any> = [];

  // Extract the cta
  const { cta, cta_link } = menu.data;
  megaMenu.cta = { cta, cta_link };

  // Extract menu items
  menu.data.slices.map(({ items, primary, id }: any) => {
    children.push(getChildren(items, previewData));
    megaMenu.items.push({
      id,
      parent_name: primary.parent,
      parent_link: primary.link,
      bottom_text: primary.bottom_text,
      children: [],
    });
  });

  // Extract item children
  try {
    const endChildren = await Promise.all(children);
    megaMenu.items.forEach((el, i) => {
      megaMenu.items[i].children = endChildren[i];
    });
  } catch (e) {
    return [];
  }

  return megaMenu;
}

async function getChildren(items: Array<any>, previewData: any) {
  const client = createClient({ previewData });
  const megaItems: Array<any> = [];

  items.map((i) => {
    // If no link or broken, do not add to array
    if (i.child_link.link_type === 'Any' || i.child_link.isBroken) { return };
    const relatedDocument = client.getByID(i.child_link.id, {
      lang: i.child_link.lang,
    });
    megaItems.push(relatedDocument);
  });

  return await Promise.all(megaItems);
}
