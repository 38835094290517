import { Theme } from '@aws-amplify/ui-react';

export const breakpoints = {
  base: 0,
  small: 576,
  medium: 768,
  large: 992,
  xl: 1200,
  xxl: 1400,
};

export const theme: Theme = {
  name: 'wildisles',
  breakpoints: {
    values: {
      ...breakpoints,
    },
  },
  tokens: {
    fonts: {
      default: {
        static: 'Georgia',
        variable: 'Georgia',
      },
    },
    colors: {
      primary: { value: '#232323' },
      secondary: { value: '#151515' },
      white: { value: '#ffffff' },
      black: { value: '#000000' },
      wwiGreen: { value: '#2c500a' },
      buttonPrimary: { value: '#fbb95d' },
      textLink: { value: '#008fbc' },
      borderMain: { value: '#6e6e6e' },
      background: {
        primary: {
          value: '#ffffff',
        },
        secondary: {
          value: '#f2f2f2',
        },
        tertiary: {
          value: '#232323',
        },
        quaternary: {
          value: '#fefaf2',
        },
        error: {
          value: '#da2226',
        }
      },
    },
    components: {
      divider: {
        borderColor: { value: '{colors.borderMain}' }
      }
    }
  }
};
