import '../styles/globals.css';
import '../styles/fonts.css';
import '../styles/typography.css';
import '../styles/links.css';
import '@aws-amplify/ui-react/styles.css';

import { Link, ThemeProvider } from '@aws-amplify/ui-react';
import { theme } from '../theme';

import Head from 'next/head';
import Script from 'next/script';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreview } from '@prismicio/next';
import { repositoryName, linkResolver } from '../prismicio';
import { Amplify } from 'aws-amplify';
import config from '../src/aws-exports';

Amplify.configure({
  ...config,
  ssr: true,
});

type TitleField = {
  text: string;
};

export default function App({ Component, pageProps }: any) {
  const { page } = pageProps;
  let titleField: TitleField = { text: '' };
  let metaTitle: string = '';
  let metaDescription: string = '';
  let metaImage: string = '';
  if (page) {
    titleField =
      page.data.title && page.data.title.length > 0
        ? page.data.title[0]
        : { text: '' };
    metaTitle = page.data.meta_title || titleField.text || '';
    metaDescription = page.data?.meta_description[0]?.text || null;
    metaImage = page.data.meta_image?.url;
  }
  const fullTitle: string = `${metaTitle.length > 0 ? `${metaTitle} | ` : ''
    }Save Our Wild Isles`;

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>{fullTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        {/* Implement OG and Twitter tags */}
        <meta name="twitter:card" content="summary" />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:url" content={linkResolver(page)} />
        {metaDescription && (
          <>
            <meta name="description" content={metaDescription} />
            <meta property="og:description" content={metaDescription} />
          </>
        )}
        {metaImage && (
          <>
            <meta property="og:image" content={metaImage} />
          </>
        )}
        {/* Add alternate links */}
        {page && page.lang && (
          <link
            key={page.lang}
            type="alternate"
            hrefLang={page.lang}
            href={linkResolver(page)}
          />
        )}
        {page &&
          page.alternate_languages.map((doc: any) => (
            <link
              key={doc.lang}
              type="alternate"
              hrefLang={doc.lang}
              href={linkResolver(doc)}
            />
          ))}
        {/* Trajan Pro Sans Semi bold - Adobe font - client has licence */}
        {/* @ts-ignore eslint-disable-next-line Ignore implementation of onload, which is valid html wise. */}
        <link rel="preload" href="https://use.typekit.net/sid3lhw.css" as="style" onload="this.onload=null;this.rel='stylesheet'" />
        <noscript><link rel="stylesheet" href="https://use.typekit.net/sid3lhw.csss" /></noscript>
        <script id="google-tag-manager">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-ND7HHWC');
          `}
        </script>
        {/* Google tag (gtag.js) */}
        <script id="rspb-global-floodlight" async>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'DC-5249822');
            `}
        </script>
        {/* End of global snippet: Please do not remove */}
      </Head>
      {/* eslint-disable-next-line @next/next/next-script-for-ga */}
      <Script async src="https://www.googletagmanager.com/gtag/js?id=DC-5249822" />
      {page?.uid === 'thankyou' && (
        <>
          {/*
            RSPB Floodlight
            Event snippet for Wild Isles Donation_PartnerWebsite_newsletter_sign-ups on : Please do not remove.
            Place this snippet on pages with events that you’re tracking.
            Creation date: 03/01/2023
          */}
          <Script id="rspb-sign-up-event">
            {`
              gtag('event', 'conversion', {
                'allow_custom_scripts': true,
                'send_to': 'DC-5249822/wldisl/pwwinews+standard'
              });
            `}
          </Script>
          <Script id="rspb-pledge-event">
            {`
              gtag('event', 'conversion', {
                'allow_custom_scripts': true,
                'send_to': 'DC-5249822/wldisl/pwwilple+standard'
              });
            `}
          </Script>
          <noscript>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src="https://ad.doubleclick.net/ddm/activity/src=5249822;type=wldisl;cat=pwwinews;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img src="https://ad.doubleclick.net/ddm/activity/src=5249822;type=wldisl;cat=pwwilple;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
          </noscript>
          {/* End of event snippet: Please do not remove */}
        </>
      )}
      <PrismicProvider
        internalLinkComponent={({ href, children, locale, ...props }: any) => (
          <Link href={href} locale={locale} {...props}>
            {children}
          </Link>
        )}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <Component {...pageProps} />
        </PrismicPreview>
      </PrismicProvider>
    </ThemeProvider>
  );
}
